/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import {FarewellHome} from 'views';
import {FarewellMain} from 'layouts';
import WithLayout from 'WithLayout';

const IndexPage = ({location}) => (
  <WithLayout
    component={FarewellHome}
    layout={FarewellMain}
    pageContext={{
      title: `Make School.org`,
      socialTitle: 'Make School.org',
      description:
        'Make School.org is a non-profit organization dedicated to creating avenues of upward mobility for students of all backgrounds, empowering them to contribute to society through science and technology innovation.',
      location,
    }}
  />
);

export default IndexPage;
